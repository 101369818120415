<template>
    <div class="group-bg-box">
        <div class="about-box d-flex d-flex-between">
            <div class="about-item-box w-40p">
                <div class="about-item-group">项目案例</div>
                <div class="about-item-swipers p-25">
                    <el-carousel height="300px">
                        <el-carousel-item v-for="(item,index) in info.img" :key="index" >
                            <img :src="item" class="w-100p">
                        </el-carousel-item>
                    </el-carousel>
                    <!-- <img src="@/assets/image/about/bg.png" class="w-100p"> -->
                </div>
            </div>
            <div class="about-item-box w-58p">
                <div class="about-item-group-title">
                    介绍
                    <div class="about-item-group-dir"></div>
                </div>
                <div class="about-item-group-desc"  v-html="info.introduce"></div>
            </div>
        </div>
        <div class="about-box m-t-20">
            <div class="about-item-box w-100p">
                <div class="about-item-group-title">
                    开发主要功能
                    <div class="about-item-group-dir"></div>
                </div>
                <div class="p-50" v-html="info.major_fun">
                </div>
            </div>
        </div>
        <div class="about-box m-t-20">
            <div class="about-item-box w-100p">
                <div class="about-item-group-title">
                    项目展示
                    <div class="about-item-group-dir"></div>
                </div>
                <div class="p-50" v-html="info.project_display">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent,ref } from 'vue'
import { useRouter } from 'vue-router'
import baseRequest from '@/service/index'

export default defineComponent({
    setup(){
        const route = useRouter()
        const info = ref({})
        console.log(route.currentRoute.value.query.id)
        const getDetail = (id) =>{
            baseRequest.post({
                url: 'api/index/case_detail',
                data:{
                    case_id:id
                }
            }).then(res=>{
                console.log(res)
                info.value = res.data
            })
        }
        getDetail(route.currentRoute.value.query.id)
        const jump = () =>{
            route.push('/news')
        }
        return{
            jump,info
        }
    }
});
</script>
<style>
.el-carousel__item--card.is-in-stage.is-hover .el-carousel__mask, .el-carousel__item--card.is-in-stage:hover .el-carousel__mask {
    opacity: 1 !important;
}
.el-carousel__mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background:none !important;
    opacity: .24;
    transition: var(--el-transition-duration-fast);
}
</style>
